export const nl = {
  Login: "Inloggen",
  Logout: "Uitloggen",
  Translation: "English",
  Home: "Home",
  "Footer content": "Footer content",
  Link: "Link",
  Welcome: "Welkom",
  "Login with DigiD": "Inloggen met DigiD",
  "Login with your account": "Inloggen met account",
  "Oops, something went wrong": "Oops, er is iets fout gegaan",
  Username: "Gebruikersnaam",
  Password: "Wachtwoord",
  Send: "Verzenden",
};
